import React, { useEffect, useState } from 'react';
import '../css/header.css';

const Header = () => {
  const [menuData, setMenuData] = useState({});

  // Fetch dropdown menu data when the component mounts
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('https://www.lva.virginia.gov/inc/menu.php');
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
        alert("Error fetching menu data.");
      }
    };

    fetchMenuData();
  }, []);

  // Dropdown hover handlers
  useEffect(() => {
    const dropdownButtons = Array.from(document.querySelectorAll('.dropbtn'));
    const dropdownMenus = Array.from(document.querySelectorAll('.dropdown'));

    dropdownButtons.forEach((dropdownButton) => {
      dropdownButton.addEventListener('mouseenter', (e) => {
        const dropdownMenu = e.target.querySelector('.dropdown');
        if (dropdownMenu) dropdownMenu.style.display = 'block';
      });

      dropdownButton.addEventListener('mouseleave', () => {
        dropdownMenus.forEach((dropdownMenu) => {
          dropdownMenu.style.display = 'none';
        });
      });
    });

    return () => {
      dropdownButtons.forEach((dropdownButton) => {
        dropdownButton.removeEventListener('mouseenter', null);
        dropdownButton.removeEventListener('mouseleave', null);
      });
    };
  }, [menuData]);

  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/igu0exu.css" />
      <div id="headerWrapper">
        <div id="header">
          <div id="logoBox">
            <a href="/">
              <img
                style={{ width: '220px', maxWidth: '100%' }}
                src="https://www.lva.virginia.gov/img/LOVA_Stack_4c_Rev.svg"
                alt="The Library of Virginia"
              />
            </a>
          </div>
          <div id="searchBox">
            <form
              id="searchbox_003101711403383086340:xhathpp67to"
              action="https://www.lva.virginia.gov/search.htm"
              style={{ margin: 0, textAlign: 'right' }}
            >
              <input type="hidden" name="cx" value="003101711403383086340:xhathpp67to" />
              <input type="hidden" name="cof" value="FORID:11" />
              <div>
                <input
                  id="searchbox"
                  alt="Search"
                  title="Search"
                  name="q"
                  type="text"
                  size="30"
                  className="searchBox__input"
                  placeholder="Search"
                />
                <button type="submit" name="sa" className="searchBox__button" aria-label="Submit Search">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 15"
                    style={{ enableBackground: 'new 0 0 15 15' }}
                    xmlSpace="preserve"
                  >
                    <path
                      fill="#F2F2F2"
                      d="M13.5,13.5L9.3,9.2c0.8-0.9,1.3-2,1.3-3.3c0-2.8-2.2-5-5-5s-5,2.2-5,5s2.2,5,5,5c1,0,1.9-0.3,2.7-0.8l4.3,4.3
                      c0.3,0.3,0.7,0.3,1,0C13.8,14.2,13.8,13.7,13.5,13.5z M1.9,5.9c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7S7.6,9.6,5.6,9.6
                      S1.9,8,1.9,5.9z"
                    />
                  </svg>
                </button>
              </div>
            </form>
            <form className="searchBox__options" name="searchTypeForm" id="searchTypeForm" action="/index.htm">
              <span>
                <input className="options__radio" id="searchTypeLVA" type="radio" name="searchType" value="site" defaultChecked />
                <label className="options__radioLabel" htmlFor="searchTypeLVA">
                  LVA Website
                </label>
              </span>
              <span>
                <input className="options__radio" id="searchTypeCAT" type="radio" name="searchType" value="catalog" />
                <label className="options__radioLabel" htmlFor="searchTypeCAT">
                  Catalog
                </label>
                [
                <span className="options__link">
                  <a href="https://lva.primo.exlibrisgroup.com/discovery/search?vid=01LVA_INST:01LVA&lang=en">
                    search options
                  </a>
                </span>
                ]
              </span>
            </form>
          </div>
        </div>
      </div>
      <div id="navWrapper">
        <nav id="mainNav">
          <ul className="primary-nav">
            <li className="dropbtn" id="forResearchers">
              <a href="#">For Researchers</a>
              <ul className="dropdown" id="forResearchersDropdown">
              {menuData["For Researchers"] && Object.entries(menuData["For Researchers"]).map(([key, value]) => (
                <li key={key}>
                    <a href={value}>{key}</a>
                </li>
              ))}
              </ul>
            </li>
            <li className="dropbtn" id="forThePublic">
              <a href="#">For the Public</a>
              <ul className="dropdown" id="forThePublicDropdown">
              {menuData["For the Public"] && Object.entries(menuData["For the Public"]).map(([key, value]) => (
                <li key={key}>
                    <a href={value}>{key}</a>
                </li>
              ))}
              </ul>
            </li>
            <li className="dropbtn" id="forLibrariesAndEducators">
              <a href="#">For Libraries &amp; Educators</a>
              <ul className="dropdown" id="forLibrariesAndEducatorsDropdown">
              {menuData["For Libraries & Educators"] && Object.entries(menuData["For Libraries & Educators"]).map(([key, value]) => (
                <li key={key}>
                    <a href={value}>{key}</a>
                </li>
              ))}
              </ul>
            </li>
            <li className="dropbtn" id="forLibrariesAndStateAgencies">
              <a href="#">For Localities &amp; State Agencies</a>
              <ul className="dropdown" id="forLibrariesAndStateAgenciesDropdown">
              {menuData["For Localities & State Agencies"] && Object.entries(menuData["For Localities & State Agencies"]).map(([key, value]) => (
                <li key={key}>
                    <a href={value}>{key}</a>
                </li>
              ))}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Header;
