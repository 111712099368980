import React from 'react';
import Layout from '../Layout';
import Divider from '@mui/material/Divider';
import { Box, Typography, Grid, Link } from '@mui/material';

export default function specialCollectionsRequest() {
  return (
    <Layout>
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Special Collections Request Form
            </Typography>
            <Divider />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <br></br>
                    <Typography variant="body1" paragraph>
                        Please email <Link href="mailto:specialcoll@lva.virginia.gov" color="inherit">specialcoll@lva.virginia.gov</Link>{' '} to submit reference questions about collections held by Special Collections at the Library of Virginia. 
                        As a reminder, the following materials are served through Special Collections: Rare Books, prints, photographs, broadsides, ephemera, architecture, maps, and State Art.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our average response time is currently two to four weeks. Response times may vary based on demand and staff availability.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Because preservation of its materials is of paramount concern, the Special Collections Department reserves the right to decline to digitize materials, or to limit the amount of material that can be safely digitized. 
                        If you have questions, please contact Special Collections at <Link href="mailto:specialcoll@lva.virginia.gov" color="inherit"> specialcoll@lva.virginia.gov</Link>.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Notice: Warning Concerning Copyright</strong>— copyright law of the United States (Title 17, United States Code) governs the making of photocopies or other reproductions of copyrighted material. Under certain conditions specified in the law, libraries and archives are authorized to furnish a photocopy or other reproduction. One of these specified conditions is that the photocopy or reproduction is not to be "used for any purpose other than private study, scholarship, or research." If a user makes a request for, or later uses a photocopy or reproduction for purposes in excess of "fair use," that user may be liable for copyright infringement. The Library’s provision of a copy does not constitute permission by any copyright holder and is not a substitute for the user’s diligent compliance with all applicable copyright requirements. This institution reserves the right to refuse to accept a copying order if, in its judgment, fulfillment of the order would involve violation of the copyright law.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Notice: Sales Tax Collection</strong>— The Library of Virginia is required to collect sales tax for online sales delivered to residents of Virginia. 
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    </Layout>
  );
}
