import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import { useAuth } from "../contexts/AuthContext";
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import logo from '../images/200Logo.svg';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';
  
  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  if (!token || !email) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Box>
          <Typography variant="h6">Incorrect URL Structure</Typography>
          <Typography variant="body1">The URL provided does not contain the information required to reset your password.</Typography>
          <Typography variant="body1">If you believe this is a mistake, please contact the help desk.</Typography>
          <Button component={Link} to="/login" variant="contained" color="primary">Back to Login Page</Button>
        </Box>
      </Container>
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let password = formData.get("password");
    let password2 = formData.get("password2");

    if (!password || !password2) {
      toast.error("Please provide your new password.");
      return;
    }

    if (password !== password2) {
      toast.error("Your passwords do not match. Please try again.");
      return;
    }

    console.log(JSON.stringify({ email, password }));

    fetch(`${process.env.REACT_APP_API_URL}/api/auth/resetpassword/${token}`, {
      method: "PUT",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success("Your password has been successfully reset!");
          navigate('/login', { state: { successMessage: 'Password reset successful!' } });
        } else {
          const errors = data.message.split(",");
          errors.forEach(error => {
            toast.error(error);
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
          <img src={logo} alt="Library of Virginia Logo" style={{ maxWidth: "200px" }} />
        </Box>
        <Typography variant="h4" align="center" gutterBottom>Reset Password</Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: "300px", maxWidth: "100%", '& .MuiTextField-root': { marginBottom: '1rem' } }}>
          <TextField type="password" name="password" id="password" label="Password" placeholder="Password" required fullWidth />
          <TextField type="password" name="password2" id="password2" label="Confirm Password" placeholder="Confirm password" required fullWidth />
          <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <Link to="/login">Back to Login</Link>
          </Box>
        </Box>
        <ToastContainer />
      </Box>
    </Container>
  );
}
