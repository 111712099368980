import { useAuth } from '../contexts/AuthContext';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Box, Grid, TextField } from '@mui/material';
import CustomerInfoForm from '../components/customerInfoForm';
import Layout from '../Layout';

export default function UserProfile() {
    const [response, setResponse] = useState([]);
    const { user } = useAuth();
    const userId = user ? user.user_id : null;
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form Submitted');
        
        const payload = {
            name: event.target.name.value,
            email: event.target.email.value,
            address: event.target.address.value,
            zip: event.target.zip.value,
            city: event.target.city.value,
            state: event.target.state ? event.target.state.value : '',
            country: event.target.country ? event.target.country.value : 'United States',
            phone: event.target.phone.value
          };
        
        // Call API to update user
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
            body: JSON.stringify(payload),
        });
  
        setResponse(response);
  
      } catch (error) {
        console.error(error);
        return;
      }

      if (response.ok){
        toast.success("User has been updated");
      } else {
        toast.error("User has not been updated");
      }
      
    };

    return (
        <Layout>
            <Box sx={{ p: 3 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CustomerInfoForm />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Layout>
    );
}