import React from 'react';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Container, Typography, TextField, Button } from '@mui/material';
import logo from '../images/200Logo.svg';

export default function ForgotPassword() {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }
  }, [user, navigate, from]);

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get('email');

    // Prepare the request payload
    const payload = {
      email: email
    };

    // Send the POST request to the API endpoint
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgotpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      // Handle the response from the API
      if (response.ok) {
        // Request was successful
        toast.success('Password reset sent, Please check email.');
      } else {
        // Request failed
        return response.json();
      }
    })
    .then(data => {
      // Handle the JSON data from the response
      if (data) {
        // Display error message from the response
        toast.error(data.message);
      }
    })
    .catch(error => {
      // Handle any errors that occur during the request
      console.error('Error:', error);
    });
  }

  return (
    <Container
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      maxWidth="md"
    >
      <div
        style={{
          textAlign: 'center',
          maxWidth: '400px',
          padding: '32px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <img
          src={logo}
          alt="Library of Virginia Logo"
          style={{ maxWidth: '200px', marginBottom: '32px' }}
        />
        <Typography variant="h5" component="h1" gutterBottom>
          Forgot Password
        </Typography>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '16px' }}
            type="email"
            name="email"
            label="Email Address"
            placeholder="example@lva.virginia.gov"
            required
          />

          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button
            component={Link}
            to="/Login"
            variant="contained"
            color="secondary"
            style={{ marginTop: '16px' }}
          >
            Login
          </Button>
        </form>
        <ToastContainer />
      </div>
    </Container>
  );
}
