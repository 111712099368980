import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, Divider, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { toast } from 'react-toastify';
import Layout from '../Layout';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';

export default function OrderLookup() {
  const [formData, setFormData] = useState({
    id: '',
    email: '',
  });
  const [responseData, setResponseData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResponseData("");
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order/lookup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error('Order data does not exist, please verify your inputs.');
      }
      const data = await response.json();
      setResponseData(data);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Order data does not exist, please verify your inputs.');
    }
  };

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    
    // Format the created_date
    const formattedCreatedDate = moment(row.created_date).format('MM/DD/YYYY HH:mm:ss');

    // Format the order details
    const formattedOrderDetails = JSON.parse(row.order_details);
    
    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.order_name}
          </TableCell>
          <TableCell align="right">{row.customer_name}</TableCell>
          <TableCell align="right">{row.customer_email}</TableCell>
          <TableCell align="right">{row.product_name}</TableCell>
          <TableCell align="right">{row.format_name}</TableCell>
          <TableCell align="right">{formattedCreatedDate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.request_name}
              </Typography>
              <Typography variant="body1" gutterBottom component="div">
                Order Details:
              </Typography>
              <List dense>
                {row.request_name === "Archive Reference Services" ? (
                  // Render for Archive Reference Services
                  Object.entries(formattedOrderDetails).map(([key, value]) => (
                    <ListItem key={key}>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `<strong>${key}</strong> ${value}` }} />} />
                    </ListItem>
                  ))
                ) : row.request_name === "Publish Materials Services" ? (
                  // Render for Publish Materials Services
                  formattedOrderDetails.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `<strong>${item.question}</strong>: ${item.answer}` }} />} />
                    </ListItem>
                  ))
                ) : row.request_name === "Special Collections" ? (
                    // Render for Special Collections
                    formattedOrderDetails.map((item, index) => (
                        <ListItem key={index}>
                        <ListItemText primary={<span>{`${item.description} `}
                          <strong>({item.product})</strong> {` for $${item.price.toFixed(2)} Quantity: ${item.quantity}`}</span>} />
                      </ListItem>
                    ))
                ) : (
                  // Render for other request types or handle as needed
                  <ListItem>
                    <ListItemText primary="Unknown request type" />
                  </ListItem>
                )}
              </List>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Layout>
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Order Lookup
      </Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Transaction ID"
              name="id"
              value={formData.id}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Lookup
            </Button>
          </Grid>
        </Grid>
      </form>
      {responseData && (
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Order Name</TableCell>
                <TableCell align="right">Customer Name</TableCell>
                <TableCell align="right">Customer Email</TableCell>
                <TableCell align="right">Product</TableCell>
                <TableCell align="right">Format</TableCell>
                <TableCell align="right">Request Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseData.data.map((row) => (
                <Row key={row.id} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  </Layout>
  );
}
