import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';

export default function OrderProcessMessage() {
  return (
    <Box>
      <Typography variant="body1" paragraph>
        Welcome to our ordering system! We're excited to have you as a customer. 
        Good news – creating an account is not required to place orders.
      </Typography>

      <Typography variant="body1" paragraph>
        However, if you'd like to have the convenience of tracking your orders and 
        easily accessing your order history, we recommend creating an account. 
        This will allow you to log in when submitting orders and manage your 
        ordering experience more efficiently.
      </Typography>

      <Typography variant="body1" paragraph>
        By having an account, you'll also have the opportunity to look up your 
        past orders whenever you need. It's a great way to keep everything 
        organized and ensure a seamless shopping experience.
      </Typography>

      <Box mt={2}>
        <Link to="/sign-up" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary">
            Create an Account
          </Button>
        </Link>

        <Box ml={2} display="inline">
          <Link to="/request" style={{ textDecoration: 'none' }}>
            <Button variant="outlined" color="primary">
              Continue as Guest
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
