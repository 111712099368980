import { useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@mui/material';
import InputMask from 'react-input-mask';

export default function PaymentInfoForm({ formSubmitted }) {
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cardCode, setCardCode] = useState('');

  const handleClearFields = () => {
    setCardNumber('');
    setExpirationDate('');
    setCardCode('');
  };

  useEffect(() => {
    if (formSubmitted) {
      // If form has been submitted, clear the payment fields
      handleClearFields();
    }
  }, [formSubmitted]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar=""
          formatChars={{ '9': '[0-9]' }}
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
        >
          {() => (
            <TextField
              label="Credit Card"
              name="cardNumber"
              required
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          mask="99/99"
          maskChar=""
          formatChars={{ '9': '[0-9]' }}
          value={expirationDate}
          onChange={(e) => setExpirationDate(e.target.value)}
        >
          {() => (
            <TextField
              label="Expiration Date (MM/YY)"
              name="expirationDate"
              required
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputMask
          mask="9999"
          maskChar=""
          formatChars={{ '9': '[0-9]' }}
          value={cardCode}
          onChange={(e) => setCardCode(e.target.value)}
        >
          {() => (
            <TextField
              label="CVV"
              name="cardCode"
              required
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
    </Grid>
  );
}
