import React, { createContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  //Check auth on refresh
  useEffect(() => {
    if (loading) {
      checkAuth();
    }
  }, [loading, user]);

  async function checkAuth() {
    const raw = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
      method: "GET",
      withCredentials: true,
      credentials: "include",
    });

    const res = await raw.json();

    if (res.success) {
      setUser(res.data);
      setLoading(false);
    } else {
      setUser(null);
      setLoading(false);
    }
  }

  const loginLocal = ({ email, password }, cb) => {
    console.log('test')
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/login/local`, {
      method: "POST",
      withCredentials: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        if (data.success) {
          setUser(data.user);
          toast.success("Successfully logged in!");
          cb();
        } else {
          toast.error("Login unsuccessful")
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const logout = (cb) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/logout`, {
      method: "DELETE",
      withCredentials: true,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setUser(null);
          toast.success("Logged out successfully!");
          cb();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const authContextValue = {
    user,
    loginLocal,
    logout,
  };
  
  if (loading) {
    return (
      <AuthContext.Provider value={authContextValue}>
        Loading...
      </AuthContext.Provider>
    );
  }
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };