import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import moment from 'moment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TablePagination from '@mui/material/TablePagination';


// Modify the createData function to accept the order object
function createData(order) {
  return {
    order,
    open: false,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  // Format the created_date
  const formattedCreatedDate = moment(row.order.created_date).format('MM/DD/YYYY h:mm A');

  // Format the order details
  const formattedOrderDetails = JSON.parse(row.order.order_details);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.order.order_name}
        </TableCell>
        <TableCell align="right">{row.order.customer_name}</TableCell>
        <TableCell align="right">{row.order.customer_email}</TableCell>
        <TableCell align="right">{row.order.product_name}</TableCell>
        <TableCell align="right">{row.order.format_name}</TableCell>
        <TableCell align="right">{formattedCreatedDate}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {row.order.request_name}
              </Typography>
              <Typography variant="body1" gutterBottom component="div">
                Order Details:
              </Typography>
              <List dense>
                {row.order.product_id === "7F84CFD2-330F-47C9-9DD6-86B43B62BCB4" ? (
                  // Render for Invoices
                  formattedOrderDetails.map((item, index) => (
                      <ListItem key={index}>
                      <ListItemText primary={<span>{`${item.description} `}
                        <strong>({item.product})</strong> {` at $${item.price.toFixed(2)} each for a total of $${(item.price * item.quantity).toFixed(2)} | Quantity Purchased: ${item.quantity}`}</span>} />
                    </ListItem>
                  ))
                ) : row.order.request_name === "Publish Materials Services" ? (
                  // Render for Publish Materials Services
                  formattedOrderDetails.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`${item.question}: ${item.answer}`} />
                    </ListItem>
                  ))
                ) : row.order.request_name === "Archive Reference Services" ? (
                  // Render for Archive Reference Services
                  Object.entries(formattedOrderDetails).map(([key, value]) => (
                    <ListItem key={key}>
                      <ListItemText primary={`${key} ${value}`} />
                    </ListItem>
                  ))
              ) : (
                  // Render for other request types or handle as needed
                  <ListItem>
                    <ListItemText primary="Unknown request type" />
                  </ListItem>
                )}
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    order: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/order`, {
          credentials: 'include', // Include cookies in the request
        });
        const json = await response.json();
        const orderRows = json.data.map((order) => createData(order));
        setOrders(orderRows);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing the number of rows per page
  };

  return (
    <Layout>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Order Name</TableCell>
              <TableCell align="right">Customer Name</TableCell>
              <TableCell align="right">Customer Email</TableCell>
              <TableCell align="right">Product</TableCell>
              <TableCell align="right">Format</TableCell>
              <TableCell align="right">Request Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row) => (
              <Row key={row.order.order_id} row={row} />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, { value: -1, label: 'All' }]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Layout>
  );
}
