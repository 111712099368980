import React from 'react';
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Container, Typography, TextField, Button } from '@mui/material';
import logo from '../images/200Logo.svg';

function Login() {
  const { loginLocal, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/';
  const successMessage = location.state?.successMessage;

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
    }

    if (successMessage) {
      toast.success(successMessage);
    }
  }, [user, navigate, from, successMessage]);

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get('email');
    let password = formData.get('password');
    if (!email) {
      toast.error('Please provide your email address.');
      return;
    }
    if (!password) {
      toast.error('Please provide your password.');
      return;
    }

    loginLocal({ email, password }, (err) => {
      if (err) return toast.error(err);
      navigate(from, { replace: true });
    });
  }

  return (
    <Container
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      maxWidth="md"
    >
      <div
        style={{
          textAlign: 'center',
          maxWidth: '400px',
          padding: '32px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
        }}
      >
        <img
          src={logo}
          alt="Library of Virginia Logo"
          style={{ maxWidth: '200px', marginBottom: '32px' }}
        />
        <Typography variant="h5" component="h1" gutterBottom>
          Log In
        </Typography>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '16px' }}
            type="email"
            name="email"
            label="Email Address"
            placeholder="example@lva.virginia.gov"
            required
          />
          <TextField
            style={{ marginBottom: '16px' }}
            type="password"
            name="password"
            label="Password"
            placeholder="Your password..."
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Login
          </Button>
          <Button
            component={Link}
            to="/sign-up"
            variant="contained"
            color="primary"
            style={{ marginTop: '16px' }}
          >
            Create Account
          </Button>
          <Button
            component={Link}
            to="/forgot"
            variant="contained"
            color="secondary"
            style={{ marginTop: '16px' }}
          >
            Forgot Password
          </Button>
        </form>
        <ToastContainer  /> 
      </div>
    </Container>
  );
}

export default Login;
